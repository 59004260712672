import React, { useEffect, useRef, useState } from "react";
import styles from "./login.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, setRedirectUrl } from "../../features/userSlice";
import axios from "axios";
const Switchuser = () => {
  const emailRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(logout());
    dispatch(setRedirectUrl(id));
  }, []);
  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: emailRef.current.value,
    };
    axios.defaults.withCredentials = true;
    try {
      const res = await axios.post(
        "http://localhost:8001/api/v1/auth/otp_login",
        data
      );
      if (res.data) {
        navigate("/verifyotp");
      }
    } catch (error) {
      const errorModal = document.getElementById("errormodal");
      errorModal.showModal();
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <form title="sign in form" onSubmit={loginUser}>
          <h1>Sign in</h1>
          <div>
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              name="email"
              id="email"
              placeholder="email@123"
              aria-label="Email address"
              aria-required="true"
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : "Sign in"}
          </button>
          <div className={styles.socialLogin}>
            <p>or sign in using </p>
            <div>
              <img src="/google.png" alt="google login" />
            </div>
          </div>
          <p>
            Don’t have a account? <Link to="/signup">Sign up now</Link>
          </p>
        </form>
        <dialog id="errormodal">
          <div className="dialogcontent">
            <h3>Login Failed</h3>
            <p>Account with this email Id does not exist</p>
            <Link to="/signup">Try Sign up</Link>
          </div>
        </dialog>
        <div>
          <img src="/login.svg" alt="Login page" />
        </div>
      </div>
    </div>
  );
};

export default Switchuser;
