import React, { useState, useEffect } from "react";
import styles from "./verification.module.css";
import { verifyOtp } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Verification = () => {
  const { user, isLoading, isError, redirectUrl, email, errorMessage } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const handleOtpChange = (e) => {
    const value = e.target.value.trim();
    if (!value) {
      console.log("called");
      setOtp("");
    }
    if (/^\d+$/.test(value)) {
      setOtp(value);
    }
  };
  useEffect(() => {
    if (user && redirectUrl) {
      const token = user.token.split(" ")[1];
      window.location.assign(`${redirectUrl}?token=${token}`);
    }
    if (user && !redirectUrl) {
      navigate("/");
    }
  }, [user]);
  const submitOtp = (e) => {
    e.preventDefault();
    const data = {
      otp: otp.toString(),
      email: email.trim(),
    };
    dispatch(verifyOtp(data));
    if (isError) {
      const errorModal = document.getElementById("errormodal");
      errorModal.showModal();
    }
  };
  const closeModal = () => {
    const errorModal = document.getElementById("errormodal");
    errorModal.close();
  };
  return (
    <div className={styles.container}>
      <div className={styles.verification}>
        <form title="otp verification" onSubmit={submitOtp}>
          <h1>Verification</h1>
          <p>Enter the One-time password sent to your Email</p>
          <div>
            <label htmlFor="otp">OTP</label>
            <input
              type="text"
              name="otp"
              id="otp"
              minLength="6"
              maxLength="6"
              pattern="[0-9]+"
              aria-label="One Time Password"
              aria-required="true"
              value={otp}
              required
              onChange={(e) => handleOtpChange(e)}
            />
          </div>
          <button disabled={isLoading}>
            {isLoading ? "Loading..." : "Verify"}
          </button>
        </form>
        <dialog id="errormodal">
          <div
            className="close"
            onClick={() => {
              const errorModal = document.getElementById("errormodal");
              errorModal.close();
            }}
          >
            X
          </div>
          <div className="dialogcontent">
            <h3> Verification Failed </h3>
            <p>{errorMessage}</p>
            <span onClick={closeModal}>Try again</span>
            <Link to="/login">Try different email</Link>
          </div>
        </dialog>
      </div>
    </div>
  );
};

export default Verification;
