import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "./features/userSlice";
function App() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  const callLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="home">
      <h3>signed in as: {user?.email}</h3>
      <button onClick={callLogout}>Logout</button>
      <div className="services">
        <p>Services</p>
        <div className="servicesGrid">
          <Link className="ol" to={"https://one-link.itoi.app/"}>
            OneLink
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
