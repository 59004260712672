import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/userSlice";
import { useNavigate } from "react-router";

const Google = () => {
  useEffect(() => {}, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (CodeResponse) => {
      try {
        const id = CodeResponse.code;
        const data = { id };
        const response = await axios.post(
          `${
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_LOCAL_PORT
              : process.env.REACT_APP_PROD_PORT
          }/api/v1/auth/login_with_google`,
          data
        );
        const token = response.data.token;
        const user = { ...response.data.data, token };
        localStorage.setItem("bbuser", JSON.stringify(user));
        dispatch(setUser(user));
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div onClick={() => login()}>
      <img src="/google.png" alt="google login" />
    </div>
  );
};

export default Google;
