import React, { useEffect, useRef, useState } from "react";
import styles from "./login.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setEmail, setRedirectUrl } from "../../features/userSlice";
import Google from "../../components/googleSignin/Google";
import { Rings } from "react-loader-spinner";
const Login = () => {
  const { user, email } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const redirectURL = query.get("redirecturl");
  const emailRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [falseLoading, setFalseLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setFalseLoading(false);
    }, 3000);
    if (user && redirectURL) {
      const token = user.token.split(" ")[1];
      window.location.assign(`${redirectURL}?token=${token}`);
    }
    if (!user && redirectURL) {
      dispatch(setRedirectUrl(redirectURL));
    }
    if (user && !redirectURL) {
      navigate("/");
    }
  }, [redirectURL, user]);
  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
    };
    axios.defaults.withCredentials = true;
    try {
      const res = await axios.post(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_LOCAL_PORT
            : process.env.REACT_APP_PROD_PORT
        }/api/v1/auth/otp_login`,
        data
      );
      if (res.data) {
        navigate("/verifyotp");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage("Something went wrong");
      }
      const errorModal = document.getElementById("errormodal");
      errorModal.showModal();
    }
    setLoading(false);
  };
  if (falseLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        <Rings
          height="200"
          width="200"
          color="#2196f3"
          radius="10"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <form title="sign in form" onSubmit={loginUser}>
          <h1>Sign in</h1>
          <div>
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => dispatch(setEmail(e.target.value))}
              placeholder="email@123"
              aria-label="Email address"
              aria-required="true"
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : "Sign in"}
          </button>
          <div className={styles.socialLogin}>
            <p>or sign in using </p>
            <Google />
          </div>
          <p>
            Don’t have a account? <Link to="/signup">Sign up now</Link>
          </p>
        </form>
        <dialog id="errormodal">
          <div
            className="close"
            onClick={() => {
              const errorModal = document.getElementById("errormodal");
              errorModal.close();
            }}
          >
            X
          </div>
          <div className="dialogcontent">
            <h3>Login Failed</h3>
            <p>{errorMessage}</p>
            <Link to="/signup">Try Sign up</Link>
          </div>
        </dialog>
        <div className={styles.img}>
          <img src="/login.svg" alt="Login page" />
        </div>
      </div>
    </div>
  );
};

export default Login;
