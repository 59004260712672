import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
let baseUrl = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_PORT
    : process.env.REACT_APP_PROD_PORT
}/api/v1/auth/`;
export const verifyOtp = createAsyncThunk(
  "user/verifyOtp",
  async (data, ThunkApi) => {
    try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${baseUrl}verify_login_otp`, data);
      const token = response.data.token;
      const user = { ...response.data.data, token };
      localStorage.setItem("bbuser", JSON.stringify(user));
      return user;
    } catch (error) {
      const errmessage = error.response.data.message;
      return ThunkApi.rejectWithValue(errmessage || "login failed");
    }
  }
);
const localUser = JSON.parse(localStorage.getItem("bbuser"));
const initialState = {
  email: "",
  user: localUser || null,
  isLoading: false,
  isError: false,
  redirectUrl: null,
  errorMessage: "",
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      toast.success("Logged in successfully");
    },
    logout: (state) => {
      localStorage.removeItem("bbuser");
      state.user = null;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
  },
  extraReducers: {
    [verifyOtp.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [verifyOtp.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = "";
      state.user = action.payload;
      toast.success("Logged in successfully");
    },
    [verifyOtp.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      toast.error(action.payload || "login failed");
    },
  },
});
export const { logout, setRedirectUrl, setEmail, setUser } = userSlice.actions;
export default userSlice.reducer;
