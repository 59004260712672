import React, { useState, useRef } from "react";
import styles from "./signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Google from "../../components/googleSignin/Google";
import { toast } from "react-hot-toast";
const SignUp = () => {
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const registeruser = async (e) => {
    e.preventDefault();
    const emailpattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validEmail = emailpattern.test(emailRef.current.value);
    if (!validEmail) {
      return toast.error("Please provide valid Email");
    }
    setLoading(true);
    const data = {
      email: emailRef.current.value,
    };
    try {
      const res = await axios.post(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_LOCAL_PORT
            : process.env.REACT_APP_PROD_PORT
        }/api/v1/auth/user_signup`,
        data
      );
      if (res.data) {
        navigate("/login");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage("Something went wrong");
      }
      const errorModal = document.getElementById("errormodal");
      errorModal.showModal();
    }
    setLoading(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.signup}>
        <form
          title="sign up form"
          onSubmit={registeruser}
          className={styles.form}
        >
          <h1>Start with your free account today.</h1>

          <div>
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              name="email"
              id="email"
              placeholder="email@123"
              aria-label="Email address"
              aria-required="true"
              required
            />
          </div>
          <div className={styles.checkboxContainer}>
            <input type="checkbox" id="terms" required />
            <label htmlFor="terms">
              I agree to the term and condition and privacy policy
            </label>
          </div>
          <button>{loading ? "Loading..." : "Sign up for free"}</button>
          <div className={styles.socialLogin}>
            <p>or sign in using </p>
            <Google />
          </div>
        </form>
        <p>
          Already have a account? <Link to="/login">Sign In</Link>
        </p>
      </div>
      <dialog id="errormodal">
        <div
          className="close"
          onClick={() => {
            const errorModal = document.getElementById("errormodal");
            errorModal.close();
          }}
        >
          X
        </div>
        <div className="dialogcontent">
          <h3>Sign Up Failed</h3>
          <p>{errorMessage}</p>
          <Link to="/login">Try Sign in</Link>
        </div>
      </dialog>
    </div>
  );
};

export default SignUp;
